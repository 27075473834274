.hscroll-line {
  bottom: 8rem;
  height: 3px;
  position: absolute;
  overflow: hidden;
  width: 80px;
  transform: rotate(90deg);
  tranform-origin: 50% 50%;
}

.hscroll-line::before,
.hscroll-line::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
}

.hscroll-line:before {
  background: #ffffff3b;
}

.hscroll-line::after {
  background: #f4f3ee;
  animation: move 3s infinite;
}

@keyframes move {
  0% {
    transform: translate3d(-200%, 0, 0);
  }
  60% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.font-outline {
  color: #f4f3ee;
  text-shadow:
   -2px -2px 0 #BCB8B1,  
    2px -2px 0 #BCB8B1,
    -2px 2px 0 #BCB8B1,
     2px 2px 0 #BCB8B1;
}